import { Dialog, DialogContent, Text } from "@fscrypto/ui";
import { useCopyWorkItem } from "./actor";
import Empty from "./components/empty";
import FileExplorer from "./components/file-explorer";
import Footer from "./components/footer";
import Header from "./components/header";
import { TeamSelector } from "./components/team-selector";

export const CopyWorkItem = () => {
  const {
    isOpen,
    closeModal,
    isReady,
    workItems,
    isLoading,
    isInitialLoading,
    setSelectedCollectionId,
    selectedCollection,
    setCurrentCollectionId,
    currentCollection,
    currentTypename,
    currentName,
    selectedContext,
    copyWorkItem,
    isCopying,
  } = useCopyWorkItem();
  if (!isReady) return null;
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        //https://github.com/radix-ui/primitives/issues/1241#issuecomment-1734547434
        setTimeout(() => {
          document.body.style.pointerEvents = "auto";
        }, 600);
        if (!open) {
          closeModal();
        }
      }}
    >
      <DialogContent>
        <div onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
          <Header typename={currentTypename} name={currentName} collectionName={null} isLoading={isInitialLoading} />
          <TeamSelector />
          <div className="ml-2 flex w-full px-2">
            <Text color="muted" size="xs">
              All members of the selected account will have access to this file
            </Text>
          </div>
          {selectedContext ? (
            <>
              <FileExplorer
                workItems={workItems}
                selectedCollection={selectedCollection}
                currentCollection={currentCollection}
                setCurrentCollectionId={setCurrentCollectionId}
                setSelectedCollectionId={setSelectedCollectionId}
                isLoading={isLoading}
              />
              <Footer
                currentTypename={currentTypename}
                context={selectedContext}
                copyWorkItem={copyWorkItem}
                selectedCollection={selectedCollection}
                currentCollection={currentCollection}
                isMoveDisabled={isLoading}
                isLoading={isCopying}
                onClose={() => {
                  setTimeout(() => {
                    document.body.style.pointerEvents = "auto";
                  }, 600);
                }}
              />
            </>
          ) : (
            <Empty />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
