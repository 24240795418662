// useUserStateMachine needs to be imported first to avoid createActor being called before the state machine is initialized
import { useUserStateMachine } from "./state/machines/user-state/user-state";
import type { userState } from "@fscrypto/domain";
import type { CurrentUser } from "@fscrypto/domain";
import { planSettings } from "@fscrypto/domain";
import { profile } from "@fscrypto/domain";
import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import clsx from "clsx";
import { isbot } from "isbot";
import { ExternalScripts } from "remix-utils/external-scripts";
import { json } from "~/remix";
import type { ClientEnv } from "~/utils/env.server";
import { clientEnv } from "~/utils/env.server";
import { CopyWorkItem } from "./features/copy-work-item";
import UncaughtErrorPage from "./features/errors/UncaughtError";
import MoveWorkItem from "./features/move-work-item/move-work-item";
import { ToastRoot } from "./features/toasts/toast-root";
import { useNotifyFromUrlMessage } from "./hooks/useNotifyFromUrlMessage";
import { services } from "./services/services.server";
import { AppStateProvider } from "./state";
import { useIsBot } from "./utils/bot-detection";
import { logger } from "./utils/logger.server";
import { commitSession } from "./utils/session.server";
import { useTrackingInitialize } from "./utils/tracking-initialize";

import "./styles/tailwind.css";
import "./styles/grid.css";
import "./styles/codemirror.css";
import "@uiw/react-md-editor/markdown-editor.css";
import "./styles/markdown-styles.css";
import "@fontsource-variable/inter/index.css";
import "@fontsource/roboto-mono/index.css";
import "@aptos-labs/wallet-adapter-ant-design/dist/index.css";
import { ErrorBoundaryComponent } from "@sentry/remix/types/utils/vendor/types";

// this allows us to pass server env vars down to the client
declare global {
  interface Window {
    ENV: ClientEnv;
  }
}

export const meta: MetaFunction = (args) => {
  return [
    { title: "Flipside Data App" },
    { name: "description", content: "On-chain data available anytime, anywhere, for free." },
  ];
};

type RootLoaderProps = {
  currentUser?: CurrentUser;
  userState: userState.UserState;
  ENV: { [key: string]: number | string | boolean };
  featureFlags: Record<string, string | boolean>;
  liveblocksToken?: string;
  userPlan?: planSettings.PlanSettings;
  profile?: profile.Profile;
};
export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  if (isbot(request.headers.get("user-agent"))) {
    return {
      currentUser: null,
      ENV: clientEnv,
      featureFlags: {},
    };
  }
  if (request.url.endsWith("/healthcheck")) {
    return json({});
  }
  const currentUser = await services.auth.getCurrentUser(request);

  // get user state, feature flags, current profile
  let userStateData, featureFlags, currentProfile;
  if (currentUser?.id && currentUser?.currentProfileId) {
    services.featureFlags.identifyUser(currentUser);

    [userStateData, featureFlags, currentProfile] = await Promise.all([
      services.userState.getByUserId(currentUser.id),
      services.featureFlags.getFeatureFlags(currentUser.id),
      currentUser.currentProfileId ? services.profiles.find(currentUser.currentProfileId) : undefined,
    ]);
  } else {
    featureFlags = await services.featureFlags.getFeatureFlags("anonymousId");
  }

  if (!currentUser) {
    const responseInit = services.tracker.setVisitorIdCookie(request);
    return json(
      {
        currentUser: currentUser,
        userState: userStateData,
        ENV: clientEnv,
        featureFlags: featureFlags ?? {},
        userPlan: undefined,
        profile: currentProfile ?? undefined,
      },
      responseInit,
    );
  }

  let respInit;

  // ensure that the user has auth0UserId set in their session
  if (currentUser) {
    if (!currentUser.auth0UserId) {
      logger.warn(`User id ${currentUser.id} missing auth0UserId, updating cookie`);
      const user = await services.users.find(currentUser.id);
      if (!user) throw new Error("User not found");
      const session = await services.auth.getUpdatedSession(request, {
        ...currentUser,
        auth0UserId: user.auth0UserId,
      });
      respInit = { headers: { "Set-Cookie": await commitSession(session) } };
    }
  }
  return json(
    {
      currentUser,
      userState: userStateData,
      ENV: clientEnv,
      featureFlags: featureFlags ?? {},
      userPlan: currentUser?.planSettings,
      profile: currentProfile ?? undefined,
    },
    respInit,
  );
};

function App() {
  const { userState, currentUser, ENV, featureFlags, userPlan, profile } =
    useLoaderData<RootLoaderProps>() as unknown as RootLoaderProps;
  useTrackingInitialize(currentUser);
  return (
    <AppStateProvider
      initialUserState={userState}
      featureFlags={featureFlags}
      user={currentUser}
      userPlan={userPlan}
      profile={profile}
    >
      <AppContent ENV={ENV} />
    </AppStateProvider>
  );
}

const AppContent = ({ ENV }: { ENV: RootLoaderProps["ENV"] }) => {
  const { theme } = useUserStateMachine();
  let isBot = useIsBot();
  useNotifyFromUrlMessage();
  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* load rudderstack data place through cloudflare worker */}
        <script src="https://rsp-production.flipsidecrypto.workers.dev/dataPlane" async />
        {/*  Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WHSDN11PZ7"></script>
        <script src="/googleAnalytics.js"></script>
      </head>
      <body className={clsx(theme, "font-inter bg-white")}>
        <div className="dark:bg-background flex min-h-screen flex-col">
          {!isBot && <ToastRoot />}
          {!isBot && <MoveWorkItem />}
          {!isBot && <CopyWorkItem />}
          <Outlet />
        </div>

        <ScrollRestoration />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`,
          }}
        />
        {isBot ? null : <Scripts />}
        <ExternalScripts />
      </body>
    </html>
  );
};

export default withSentry(App);

export const ErrorBoundary: ErrorBoundaryComponent = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  if (!isRouteErrorResponse(error)) return null;
  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <UncaughtErrorPage message={error.data.message} name={error.data.name} stack={error.data.stack} />
        <Scripts />
      </body>
    </html>
  );
};

export const shouldRevalidate = () => {
  return false;
};
