import type { WorkItem } from "@fscrypto/domain/work-item";
import { FileTreeIcon } from "~/features/file-explorer/components/file-explorer-row/file-tree-icon";

interface HeaderProps {
  typename: WorkItem["typename"] | null;
  name: string | null;
  collectionName: string | null;
  isLoading: boolean;
}

const Header = ({ typename, name, isLoading }: HeaderProps) => {
  if (isLoading) {
    return (
      <div className="dark:border-gray-80 w-full animate-pulse border-b p-2">
        <div className="flex h-8 w-full items-center">
          <div className="mr-2 h-5 w-5 rounded-full bg-gray-200 dark:bg-gray-700"></div>
          <div className="h-6 flex-1 rounded-full bg-gray-200 dark:bg-gray-700 "></div>
        </div>
      </div>
    );
  }
  return (
    <div onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
      <div className="dark:border-gray-80 border-b p-2">
        <div className="w-full overflow-hidden truncate">
          <Title name={name ?? ""} type={typename} />
        </div>
      </div>
    </div>
  );
};

export default Header;

const Title = ({ name, type }: { name: string; type: WorkItem["typename"] | null }) => {
  return (
    <div className="text-gray-70 dark:text-gray-40 flex items-center overflow-hidden truncate font-medium">
      <FileTreeIcon type={type ?? "query"} />
      <p className="ml-1 truncate">Copy "{name}"</p>
    </div>
  );
};
